import 'twin.macro';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import ContentBlocks from '../sanity/ContentBlocks';
import HomeHero from '../components/home/HomeHero';

const ServiceIndividual = ({ data }) => {
  const { service } = data;
  if (!service) return null;
  return (
    <Layout>
      <Seo
        title={service.title}
        description={service.description}
        keywords={service.seoKeywords}
        image={
          service.seoFeaturedImage &&
          service.seoFeaturedImage.asset &&
          service.seoFeaturedImage.asset.url
        }
      />
      <HomeHero
        title={service.title}
        fluid={
          service.seoFeaturedImage &&
          service.seoFeaturedImage.asset &&
          service.seoFeaturedImage.asset.gatsbyImageData
        }
        description={service.description}
      />

      {service.contentBlocks &&
        service.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}
    </Layout>
  );
};

ServiceIndividual.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceIndividual;

export const pageQuery = graphql`
  query ($slug: String!) {
    service: sanityService(slug: { current: { eq: $slug } }) {
      title
      description
      seoKeywords
      slug {
        current
      }
      seoFeaturedImage {
        asset {
          gatsbyImageData
          _id
          url
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`;
